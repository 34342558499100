<template>
  <!-- form -->
  <validation-observer ref="locationDataForm">
    <b-form
      @submit.prevent="validationForm"
    >
      <b-card>
        <b-col>
          <b-row class="mt-2">
            <!-- ciiu -->
            <b-col
              md="6"
            >
              <b-form-group
                label="CIIU*"
                label-for="ciiu"
              >
                <v-select
                  id="ciiu"
                  v-model="ciiu"
                  :options="options"
                  name="ciiu"
                  :disabled="!canEdit"
                >
                  <span slot="no-options">
                    Lo sentimos, no hay opciones que coincidan.
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- companySize -->
            <!-- <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Tamaño de la empresa"
                label-for="companySize"
              >
                <b-form-input
                  id="companySize"
                  v-model="companySize"
                  name="companySize"
                />
              </b-form-group>
            </b-col> -->
            <!-- mainActivity -->
            <b-col
              md="6"
              sm="12"
            >
              <b-form-group
                label="Actividad principal"
                label-for="mainActivity"
              >
                <b-form-input
                  id="mainActivity"
                  v-model="mainActivity"
                  name="mainActivity"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- address -->
            <b-col
              md="12"
            >
              <b-form-group
                label="Dirección*"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dirección"
                  rules="max:150"
                  vid="address"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    name="address"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- streetNumber -->
            <b-col
              md="2"
            >
              <b-form-group
                label="Número"
                label-for="streetNumber"
              >
                <b-form-input
                  id="streetNumber"
                  v-model="streetNumber"
                  name="streetNumber"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- block -->
            <b-col
              md="2"
            >
              <b-form-group
                label="Manzana"
                label-for="block"
              >
                <b-form-input
                  id="block"
                  v-model="block"
                  name="block"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- lot -->
            <b-col
              md="2"
            >
              <b-form-group
                label="Lote"
                label-for="lot"
              >
                <b-form-input
                  id="lot"
                  v-model="lot"
                  name="lot"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- street -->
            <b-col
              md="6"
            >
              <b-form-group
                label="Urbanización"
                label-for="street"
              >
                <b-form-input
                  id="street"
                  v-model="street"
                  name="street"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- apartmentNumber -->
            <b-col
              md="3"
            >
              <b-form-group
                label="N° Departamento"
                label-for="apartment"
              >
                <b-form-input
                  id="apartmentNumber"
                  v-model="apartmentNumber"
                  name="apartmentNumber"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- inside -->
            <b-col
              md="3"
            >
              <b-form-group
                label="Interior"
                label-for="inside"
              >
                <b-form-input
                  id="inside"
                  v-model="inside"
                  name="inside"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <!-- district -->
            <b-col
              md="3"
            >
              <b-form-group
                label="Distrito*"
                label-for="district"
              >
                <validation-provider
                  #default="{ errors }"
                  name="distrito"
                  vid="district"
                >
                  <b-form-input
                    id="district"
                    v-model="district"
                    :state="errors.length > 0 ? false:null"
                    name="district"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- province -->
            <b-col
              md="3"
            >
              <b-form-group
                label="Provincia*"
                label-for="province"
              >
                <validation-provider
                  #default="{ errors }"
                  name="provincia"
                  vid="province"
                >
                  <b-form-input
                    id="province"
                    v-model="province"
                    :state="errors.length > 0 ? false:null"
                    name="province"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- department -->
            <b-col
              md="3"
            >
              <b-form-group
                label="Departamento*"
                label-for="department"
              >
                <validation-provider
                  #default="{ errors }"
                  name="departamento"
                  vid="department"
                >
                  <b-form-input
                    id="department"
                    v-model="department"
                    :state="errors.length > 0 ? false:null"
                    name="department"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- country -->
            <b-col
              md="2"
            >
              <b-form-group
                label="País*"
                label-for="country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="país"
                  vid="country"
                >
                  <b-form-select
                    id="country"
                    v-model="country"
                    :options="optionsCountry"
                    text-field="varDescripcion"
                    value-field="varCodigo"
                    :state="errors.length > 0 ? false:null"
                    name="country"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- reference -->
            <b-col
              md="4"
            >
              <b-form-group
                label="Referencia*"
                label-for="reference"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referencia"
                  vid="reference"
                >
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    :state="errors.length > 0 ? false:null"
                    name="reference"
                    :disabled="!canEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- UBIGEO -->
            <b-col
              md="3"
            >
              <!-- ubigee -->
              <b-form-group
                label="Ubigeo*"
                label-for="ubigee"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ubigeo"
                  rules="min:6|max:6"
                  vid="ubigee"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                        variant="outline-primary"
                        class="btn-icon"
                      >
                        <feather-icon
                          size="15"
                          icon="InfoIcon"
                        />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      id="ubigee"
                      v-model="ubigee"
                      :formatter="formatterNumber"
                      :state="errors.length > 0 ? false:null"
                      name="ubigee"
                      :disabled="!canEdit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row
                class="justify-content-center m-2"
              >
                <b-badge
                  href="/ubigeo"
                  variant="light-primary"
                  target="_blank"
                  style="white-space: pre-wrap;"
                > Puede encontrar su UBIGEO haciendo click AQUÍ </b-badge>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!-- submit button -->
      <b-row
        class="justify-content-center my-2"
      >
        <b-col
          sm="6"
          md="3"
        >
          <!-- return button -->
          <b-button
            class="my-1"
            variant="secondary"
            :to="{name:'Client-data'}"
            block
          >
            Volver
          </b-button>
        </b-col>
        <b-col
          sm="6"
          md="3"
        >
          <b-button
            class="my-1"
            variant="primary"
            block
            type="submit"
          >
            {{ canEdit ? "Guardar y avanzar" : "Siguiente" }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BButton, BRow, BCol, VBTooltip, BInputGroup, BInputGroupPrepend, BBadge, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ciiuOptions from '@utils/ciiuOptions'
import countriesOptions from '@utils/countriesOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BForm,
    BCard,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      // User email data
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
      // Initial values for the data
      address: '',
      street: '',
      streetNumber: '',
      block: '',
      lot: '',
      apartmentNumber: '',
      inside: '',
      // companySize: '',
      district: '',
      province: '',
      department: '',
      country: '',
      ubigee: '',
      reference: '',
      mainActivity: '',
      // Options values
      options: ciiuOptions,
      optionsCountry: countriesOptions,
      ciiu: null,
      userType: null,
      // Validation rules
      required,
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.address = dataU.locationData && dataU.locationData.address ? dataU.locationData.address : ''
    this.street = dataU.locationData && dataU.locationData.street ? dataU.locationData.street : ''
    this.streetNumber = dataU.locationData && dataU.locationData.streetNumber ? dataU.locationData.streetNumber : ''
    this.block = dataU.locationData && dataU.locationData.block ? dataU.locationData.block : ''
    this.lot = dataU.locationData && dataU.locationData.lot ? dataU.locationData.lot : ''
    this.apartmentNumber = dataU.locationData && dataU.locationData.apartmentNumber ? dataU.locationData.apartmentNumber : ''
    this.inside = dataU.locationData && dataU.locationData.inside ? dataU.locationData.inside : ''
    // this.companySize = dataU.locationData && dataU.locationData.companySize ? dataU.locationData.companySize : ''
    this.district = dataU.locationData && dataU.locationData.district ? dataU.locationData.district : ''
    this.province = dataU.locationData && dataU.locationData.province ? dataU.locationData.province : ''
    this.department = dataU.locationData && dataU.locationData.department ? dataU.locationData.department : ''
    this.country = dataU.locationData && dataU.locationData.country ? dataU.locationData.country : ''
    this.ubigee = dataU.locationData && dataU.locationData.ubigee ? dataU.locationData.ubigee : ''
    this.reference = dataU.locationData && dataU.locationData.reference ? dataU.locationData.reference : ''
    this.mainActivity = dataU.locationData && dataU.locationData.mainActivity ? dataU.locationData.mainActivity : ''
    this.ciiu = dataU.locationData && dataU.locationData.ciiu ? dataU.locationData.ciiu : null
    this.userType = dataU.type
    if (dataU.clientData && dataU.type === 0) {
      this.canEdit = true
    }
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.$router.replace('/registro/aspectos-administracion')
      } else {
        this.$refs.locationDataForm.validate().then(success => {
          if (success) {
            useJwt.locationData({
              email: this.email,
              ruc: this.ruc,
              locationData: {
                address: this.address,
                street: this.street,
                streetNumber: this.streetNumber,
                block: this.block,
                lot: this.lot,
                apartmentNumber: this.apartmentNumber,
                inside: this.inside,
                // companySize: this.companySize,
                district: this.district,
                province: this.province,
                department: this.department,
                country: this.country,
                ubigee: this.ubigee,
                reference: this.reference,
                mainActivity: this.mainActivity,
                ciiu: this.ciiu,
              },
            })
              .then(({ data }) => {
                const user = data.user.value
                localStorage.setItem('userData', JSON.stringify(user))
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'Location-data', status: user.locationData.completed },
                  { root: true },
                )
                this.$router.replace('/registro/aspectos-administracion')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Éxito',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Se ha guardado tu información con éxito.',
                        autoHideDelay: 5000,
                      },
                    })
                  })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.locationDataForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
